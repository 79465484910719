<template>
  <div>
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="true"
      :column-auto-width="true"
    >
      <DxColumn data-field="pId" caption="Id" />
      <DxColumn data-field="stokKodu" caption="Stok Kodu" />
      <DxColumn data-field="stockName" caption="Stok Adı" />
      <DxColumn data-field="birim" caption="Adet" />
      <DxColumn data-field="siparisMiktari" alignment="right" caption="Miktar" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="hareketBirimFiyat" alignment="right" caption="Birim fiyat" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="hareketTutari" alignment="right" caption="Hareket Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="paraBirimi" caption="Para Birim" />
      <DxColumn data-field="kur" caption="Kur" />
      <DxColumn data-field="birimUcreti" alignment="right" caption="Birim Ücreti" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="yerelParaTutari" alignment="right" caption="YP.Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="kdvYuzdesi" alignment="right" caption="Kdv Yüzdesi" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="iskontoluTutar" alignment="right" caption="İskontolu tutar" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="kdvTutari" alignment="right" caption="Kdv Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxSummary>
          <DxTotalItem
            column="siparisMiktari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="hareketTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="yerelParaTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="iskontoluTutar"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="kdvTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
        </DxSummary>
    </DxDataGrid>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import { locale } from 'devextreme/localization';

export default {
  components: { DxDataGrid, DxColumn, DxSummary, DxTotalItem },
  props: {
    siparisDetayData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSource: this.siparisDetayData.data.siparisDetayList,
    };
  },
  methods: {
    customizeDecimalDxGrid,
    customizeKur(cellValue) {
      if (cellValue.value) {
        // return cellValue.value.replace('.', ',')
        return cellValue.value
      }
      return ''
    },
  },
  created() {
    locale('tr')
  },
};
</script>

<style>
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
